export const SVG_FOLDER_TREE_ICONS = {
    control_module_folder: {
        url: 'assets/image/icons/folder-tree/control-module-folder.svg',
        name: 'control_module_folder',
    },
    tags_and_params_folder: {
        url: 'assets/image/icons/folder-tree/tags-and-parameters-folder.svg',
        name: 'tags_and_params_folder',
    },
    flowchart_and_logics_folder: {
        url: 'assets/image/icons/folder-tree/flowchart-and-logics-folder.svg',
        name: 'flowchart_and_logics_folder',
    },
    logic_set_folder: {
        url: 'assets/image/icons/folder-tree/logic-set-folder.svg',
        name: 'logic_set_folder',
    },
    hmi_page_folder: {
        url: 'assets/image/icons/folder-tree/hmi-page.svg',
        name: 'hmi_page_folder',
    },
    batch_configuration_folder: {
        url: 'assets/image/icons/folder-tree/batch-configuration-folder.svg',
        name: 'batch_configuration_folder',
    },
    batch_folder: {
        url: 'assets/image/icons/folder-tree/batch-folder.svg',
        name: 'batch_folder',
    },
    logic_folder: {
        url: 'assets/image/icons/folder-tree/logic-folder.svg',
        name: 'logic_folder',
    },

    collapse_all: {
        url: 'assets/image/icons/folder-tree/collapse-all.svg',
        name: 'collapse_all',
    },
    expand_all: {
        url: 'assets/image/icons/folder-tree/expand-all.svg',
        name: 'expand_all',
    },
    show_all_routes: {
        url: 'assets/image/icons/folder-tree/all-routes.svg',
        name: 'show_all_routes',
    },
    manual_route: {
        url: 'assets/image/icons/manual.svg',
        name: 'manual_route',
    },
    virtual_parameter: {
        url: 'assets/image/icons/folder-tree/virtual-parameter.svg',
        name: 'virtual_parameter',
    },
};
