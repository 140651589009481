export const SVG_PROJECT_ICONS = {
    add_new: { url: 'assets/image/icons/add-new.svg', name: 'cpr_add_new' },
    arrow_back: { url: 'assets/image/icons/arrow-back.svg', name: 'cpr_arrow_back' },
    basket: { url: 'assets/image/icons/basket.svg', name: 'cpr_basket' },
    basket_red: { url: 'assets/image/icons/basket-red.svg', name: 'cpr_basket_red' },
    check: { url: 'assets/image/icons/check.svg', name: 'cpr_check' },
    close: { url: 'assets/image/icons/close.svg', name: 'cpr_close' },
    drag_cursor: { url: 'assets/image/icons/drag-cursor.svg', name: 'cpr_drag_cursor' },
    duplicate: { url: 'assets/image/icons/duplicate.svg', name: 'cpr_duplicate' },
    edit: { url: 'assets/image/icons/edit.svg', name: 'cpr_edit' },
    generate_routes: { url: 'assets/image/icons/generate-routes.svg', name: 'cpr_generate_routes' },
    manual: { url: 'assets/image/icons/manual.svg', name: 'cpr_manual' },
    search: { url: 'assets/image/icons/search.svg', name: 'cpr_search' },
    selected: { url: 'assets/image/icons/selected.svg', name: 'cpr_selected' },
    show_tags: { url: 'assets/image/icons/show-tags.svg', name: 'cpr_show_tags' },
    release_project: {
        url: 'assets/image/icons/toolbar/release-project.svg',
        name: 'ctb_release_project',
    },
    generate: { url: 'assets/image/icons/generate.svg', name: 'cpr_generate' },
    download: {url: 'assets/image/icons/download.svg', name: 'cpr_download'},
    save: {url: 'assets/image/icons/save.svg', name: 'cpr_save'},

};
